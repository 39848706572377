<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <ProjectUserStoryForm
      :project-user-story="newProjectUserStory"
      :errors="projectUserStoryValidationErrors"
      :disabled="$store.getters.loading['post:api/project-user-stories']"
      @clear:errors="CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS"
      @cancel="onCancel"
      @save="onSave"
      @upload:attachments="pendingAttachments = $event"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProjectUserStoryForm from '@/components/forms/ProjectUserStoryForm';

export default {
  name: 'CreateProjectUserStory',

  components: { ProjectUserStoryForm },

  data() {
    return {
      pendingAttachments: [],
    };
  },

  computed: {
    ...mapState('projectUserStories', ['newProjectUserStory', 'projectUserStoryValidationErrors']),
  },

  created() {
    this.SET_NEW_PROJECT_USER_STORY({
      ...this.newProjectUserStory,
      project_id: +this.$route.params.projectId,
    });
    this.SET_PROJECT_USER_STORY_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('projectUserStories', [
      'storeProjectUserStory',
      'uploadProjectUserStoryAttachments',
    ]),
    ...mapMutations('projectUserStories', [
      'SET_NEW_PROJECT_USER_STORY',
      'SET_PROJECT_USER_STORY_VALIDATION_ERRORS',
      'CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS',
    ]),

    onCancel(projectUserStory) {
      this.SET_NEW_PROJECT_USER_STORY(projectUserStory);
      this.goBack();
    },

    onSave(projectUserStory) {
      this.storeProjectUserStory(projectUserStory).then((createdUserStory) => {
        if (this.pendingAttachments.length) {
          this.uploadProjectUserStoryAttachments({
            projectUserStory: createdUserStory,
            attachments: this.pendingAttachments,
          });
        }

        this.goBack();
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>
